import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import 'components/player/cards/totalscorecard.css';  
import Stats from 'helpers/stats';
import ScorecardModel from "model/scorecardModel";
import { Carousel } from "react-bootstrap";

const GRAPH_Y_TITLE = "Score";

const TREND_COLORS = {
    GOOD: "#008000",
    BAD: "#C00000",
};

const BUTTON_TEXT = {
    TEXT: "Chart",
    CHART: "Return",
};

const SERIES_NAMES = {
    DATA: "",
    TREND: "Trending Score",
};

const GRAPH_X_AXIS_STATES = {
    ROUND: "Round",
    DATE: "Date",
};

const CARD_WIDTHS = {
    TEXT: "",
    CHART: "100%",
};

interface AppProps { 
    player: string;
    propsId: string;
    selectedScorecards: Array<ScorecardModel>;
    previousScorecards: Array<ScorecardModel>;
}
 
interface AppState {
    displayExpanded: boolean;
    graphXAxisState: string;
}

class TotalScoreCard extends React.Component<AppProps, AppState> {

    stats : {
        score: string;
        scoreToPar: string;
    }
    trends : {
        score: string;
        scoreToPar: string;
    }
    trendColors : {
        score: string;
        scoreToPar: string;
    };
    propsOldId: string;
    chartOptions: any;
    width: string;
    button_text: string;

    constructor(props: any){
        super(props);

        this.propsOldId = "";
        this.width = "";
        this.button_text = BUTTON_TEXT.TEXT
        this.stats = {
            score: "",
            scoreToPar: "",
        }
        this.trends = {
            score: "",
            scoreToPar: "",
        }
        this.trendColors = {
            score: "",
            scoreToPar: "",
        };
        this.chartOptions = {
            title: {
                text: "",
                floating: true,
            },
            series: [
                { name: SERIES_NAMES.DATA, data: [], type: 'scatter' },
                { name: SERIES_NAMES.TREND, data: [], type: 'spline', marker: { enabled: false } }
            ],
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: GRAPH_Y_TITLE
                }
                
            },
            tooltip: {

            },
            xAxis: {
                type: "linear"
            }
        };

        this.state = {
            graphXAxisState: GRAPH_X_AXIS_STATES.ROUND,
            displayExpanded: false,
        };
        this.selectDisplay = this.selectDisplay.bind(this);
        this.selectGraphXAxis = this.selectGraphXAxis.bind(this);
    } 

    selectGraphXAxis(selection: string) {
        this.calculateGraphs(selection);
        this.setState({
            graphXAxisState: selection,
        });

    }

    selectDisplay() {
        if(this.state.displayExpanded) {
            this.width = CARD_WIDTHS.TEXT;
            this.button_text = BUTTON_TEXT.TEXT;
        } else {
            this.width = CARD_WIDTHS.CHART;
            this.button_text = BUTTON_TEXT.CHART;
        }
        this.setState({
            displayExpanded: !this.state.displayExpanded,
        });

    }

    calculateStats() {
        let score: number;
        let scoreToPar: number;
        let score_super: number;
        let scoreToPar_super: number;
        let selected_count: number;
        let previous_count: number;

        //get averages for stats
        score = 
            Stats.getAverage(
            Stats.getTotalScores(this.props.selectedScorecards)); 
        score_super = score - 
            Stats.getAverage(
            Stats.getTotalScores(this.props.previousScorecards)); 
        scoreToPar = 
            Stats.getAverage(
            Stats.getScoreToPars(this.props.selectedScorecards));
        scoreToPar_super = scoreToPar - 
            Stats.getAverage(
            Stats.getScoreToPars(this.props.previousScorecards));

        //get total counts, assumes score and score to par have same count
        selected_count = Stats.getTotalScores(this.props.selectedScorecards).length;
        previous_count = Stats.getTotalScores(this.props.previousScorecards).length;

        //if count is zero, then no current valid scores were found. put default message instead.
        //else use calculated values
        if(selected_count === 0) {
            const message = "N/A";
            this.stats.scoreToPar = message;
            this.stats.score = message;
        } else {
            this.stats.score = score.toFixed(0);
            this.stats.scoreToPar = scoreToPar.toFixed(0);
        }

        //if either count is zero, then do not show trending value.
        //else show trending data
        if(selected_count === 0 || previous_count === 0) {
            const message = "";
            this.trends.scoreToPar = message;
            this.trends.score = message;
        } else {
            if(score_super <= 0) { 
                this.trends.score = "\u2193" + Math.abs(score_super).toFixed(0);
                this.trendColors.score = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.score = "\u2191" + score_super.toFixed(0);
                this.trendColors.score = TREND_COLORS.BAD;
            }

            if(scoreToPar_super <= 0) { 
                this.trends.scoreToPar = "\u2193" + Math.abs(scoreToPar_super).toFixed(0);
                this.trendColors.scoreToPar = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.scoreToPar = "\u2191" + scoreToPar_super.toFixed(0);
                this.trendColors.scoreToPar = TREND_COLORS.BAD;
            }
        }
    }

    calculateGraphs(xAxis: string) {
        var yAxisData;
        var smoothYAxisData;
        var xAxisData = [];
        var data = [];
        var smoothData = [];
        var type;


        yAxisData = Stats.getTotalScores(this.props.selectedScorecards);

        switch(xAxis) {
            case GRAPH_X_AXIS_STATES.ROUND:
                for(let i = 1; i <= yAxisData.length; i++) {
                    xAxisData.push(i);
                }
                type="linear";
                break;
            default: //case GRAPH_X_AXIS_STATES.DATE:
                xAxisData = Stats.getDates(this.props.selectedScorecards);
                type="datetime";
                break;
        }

        smoothYAxisData = Stats.getSmoothScores(yAxisData).map(x => Math.round(x*100)/100)
        for(let i = 0; i < yAxisData.length; i++) {
            data.push([xAxisData[i],Math.round(yAxisData[i]*100)/100]);
            smoothData.push([xAxisData[i],smoothYAxisData[i]]);
        }
        
        this.chartOptions = {
            title: {
                text: "",
                floating: true,
            },
            series: [
                { name: SERIES_NAMES.DATA, data: [], type: 'scatter' },
                { name: SERIES_NAMES.TREND, data: [], type: 'spline', marker: { enabled: false } }
            ],
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: GRAPH_Y_TITLE
                }
            },
            xAxis: {
                type: type,
            },
        }

        this.chartOptions.series[0].data = data;
        this.chartOptions.series[1].data = smoothData;
    }

    render() {
        if(this.props.propsId !== this.propsOldId) { //year, month, course filter changed
            this.calculateGraphs(this.state.graphXAxisState);
            this.calculateStats();
            this.propsOldId = this.props.propsId;
        }
        return (
            <div className="wrapper" style={{ width: this.width }}>
                <Card className="card">
                    <Card.Title className="card_title">Score Averages</Card.Title>
                    {this.state.displayExpanded && <div className="chart">
                        <HighchartsReact
                                highcharts={Highcharts}
                                options={this.chartOptions}
                        />
                        <DropdownButton id="xAxisDropDown" align="center" title={this.state.graphXAxisState}>
                            <Dropdown.Item onClick={() => this.selectGraphXAxis(GRAPH_X_AXIS_STATES.ROUND)}>{GRAPH_X_AXIS_STATES.ROUND}</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.selectGraphXAxis(GRAPH_X_AXIS_STATES.DATE)}>{GRAPH_X_AXIS_STATES.DATE}</Dropdown.Item>
                        </DropdownButton>
                    </div>}
                    {!this.state.displayExpanded && <div className="text">
                        <Carousel className="carousel" indicators={false} interval={7500}>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.score}</p>
                                    <h1 className="value">{this.stats.score}</h1>
                                    <p className="trend" style={{color: this.trendColors.score}}>{this.trends.score}</p>
                                </div>
                                <p className="description">Total Score</p>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.scoreToPar}</p>
                                    <h1 className="value">{this.stats.scoreToPar}</h1>
                                    <p className="trend" style={{color: this.trendColors.scoreToPar}}>{this.trends.scoreToPar}</p>
                                </div>
                                <p className="description">Score To Par</p>
                            </Carousel.Item>
                        </Carousel>
                    </div> }
                    <Button className="button" variant="link" onClick = {this.selectDisplay} >{this.button_text}</Button>
                </Card>                         
            </div>
        );
    }
}

export default TotalScoreCard;