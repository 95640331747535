import React from 'react';
import 'components/app/App.css';
import 'custom.scss';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Home from 'components/home/home'
import Player from 'components/player/player';
import Course from 'components/course/course';
import Signup from 'components/signup/signup';
import Login from 'components/login/login';
import Leaderboard from 'components/leaderboard/leaderboard';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { Auth } from 'aws-amplify';
import useIsLoggedIn from 'helpers/useIsLoggedIn';
import logo from 'images/shanktext.png'

function signout(eventKey, event) {
  Auth.signOut();
  localStorage.removeItem('username');
  window.location.reload();
}

function App() {
  const isLoggedIn = useIsLoggedIn();

  //Auth.currentAuthenticatedUser().then(response => console.log(response));
  return (
    <div className="Home">
      <Router>
          <div className="header">
            <Navbar bg="light" expand="lg">
              <Nav.Link as={Link} to="/home">
                <img class="logo" src={logo} />
              </Nav.Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link as={Link} to="/home">Home</Nav.Link>
                  <Nav.Link as={Link} to="/leaderboard">Leaderboard</Nav.Link>
                </Nav>
                <Nav className="justify-content-end" onSelect={signout}>
                  {!isLoggedIn && <Nav.Link as={Link} to="/login">Sign in</Nav.Link>}
                  {!isLoggedIn && <Nav.Link as={Link} to="/signup">Sign up</Nav.Link>}
                  {isLoggedIn && <Nav.Link as={Link} to={`/player/${localStorage.getItem('username')}`}>{localStorage.getItem('username')}</Nav.Link>}
                  {isLoggedIn && <Nav.Link eventKey="signout">Sign out</Nav.Link>}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
          <Container>       
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/index.html">
                <Home />
              </Route>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/leaderboard">
                <Leaderboard />
              </Route>
              <Route path="/player/:playerName" component={Player} />
              <Route path="/course/:courseName/:city/:state" component={Course} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
            </Switch>
          </Container>
          <div class="footer">
            © 2021 shankscores.com
          </div>
        </Router>
    </div>
  );
}

export default App;
