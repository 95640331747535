import React from "react";
import ViewScorecards from "components/viewScorecards/viewScorecards";
import 'components/player/player.css';
import Select from 'react-select';
import { ValueType } from "react-select";
import Stats from 'helpers/stats';
import ScorecardModel from "model/scorecardModel";
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment'
import { API } from 'aws-amplify';
import Scorecard from 'components/scorecard/scorecard';
import TotalScoreCard from "./cards/totalscorecard";
import HoleScoreCard from "./cards/holescorecard";
import RoundsCard from "./cards/roundscard";
import ChokeCard from "./cards/chokecard";
import HolePctCard from "./cards/holepctcard";

type OptionType = { label: string; value: number };
type CourseOptionType = {label: string; value: string};

interface AppProps { 
    playerName: string;
}
 
interface AppState {
    allScorecards: Array<ScorecardModel>;
    yearOptions: {
        value: number;
        label: string;
        isdisabled: boolean;
    }[];
    monthOptions: {
        value: number;
        label: string;
        isdisabled: boolean;
    }[];
    courseOptions: {
        value: string;
        label: string;
        isdisabled: boolean;
    }[];
}

class Player extends React.Component<RouteComponentProps<AppProps>, AppState> {
    selectedScorecards: Array<ScorecardModel>;
    previousScorecards: Array<ScorecardModel>;
    yearSelection: number;
    monthSelection: number;
    courseSelection: string;

    constructor(props: any){
        super(props);

        this.selectedScorecards = Array<ScorecardModel>();
        this.previousScorecards = Array<ScorecardModel>();
        this.yearSelection = -1;
        this.monthSelection = -1;
        this.courseSelection = "All";

        this.state = {
            allScorecards: Array<ScorecardModel>(),
            yearOptions: [{value: -1, label: "All", isdisabled: false}],
            monthOptions: [{value: -1, label: "All", isdisabled: false}],
            courseOptions: [{value: "All", label: "All", isdisabled: false}],
        };
    }

    filterScorecards() {
        this.selectedScorecards = Stats.filterScorecards(this.state.allScorecards, this.yearSelection, this.monthSelection, this.courseSelection);
        let year = this.yearSelection;
        let month = this.monthSelection;
        if(this.yearSelection > 0 && this.monthSelection >= 0) {
            month = this.monthSelection - 1;
            year = this.yearSelection;
            if(this.monthSelection === 0) {
                month = 11;
                year = this.yearSelection - 1;
            }
            this.previousScorecards = Stats.filterScorecards(this.state.allScorecards, year, month, this.courseSelection);
        }
        else if(this.yearSelection > 0) {
            this.previousScorecards = Stats.filterScorecards(this.state.allScorecards, this.yearSelection-1, this.monthSelection, this.courseSelection);
        }
        else {
            this.previousScorecards = [];
        }
    }

    yearSelected = (selectedOption:ValueType<OptionType,false>) => {
        const value = (selectedOption as OptionType).value;
        this.yearSelection = value;
        this.filterScorecards();
        this.updateFilterDropdowns();
    }

    monthSelected = (selectedOption:ValueType<OptionType,false>) => {
        const value = (selectedOption as OptionType).value;
        this.monthSelection = value;
        this.filterScorecards();
        this.updateFilterDropdowns();
    }

    courseSelected = (selectedOption:ValueType<CourseOptionType,false>) => {
        const value = (selectedOption as CourseOptionType).value;
        this.courseSelection = value;
        this.filterScorecards();
        this.updateFilterDropdowns();
    }

    updateFilterDropdowns() {
        let yearOptions = this.state.yearOptions;
        let monthOptions = this.state.monthOptions;
        let courseOptions = this.state.courseOptions;

        //start with disabling all options except "All"
        let scorecards = Stats.filterScorecards(this.state.allScorecards, -1, this.monthSelection, this.courseSelection);
        for(let i = 0; i < yearOptions.length; i++) {
            yearOptions[i].isdisabled = true;
            if(yearOptions[i].value>0) {
                if(scorecards.find(element => moment(element.teeTime).year() === yearOptions[i].value)) {
                    yearOptions[i].isdisabled = false;
                }
            }
            else {
                yearOptions[i].isdisabled = false;
            }
        }
        scorecards = Stats.filterScorecards(this.state.allScorecards, this.yearSelection, -1, this.courseSelection);
        for(let i = 0; i < monthOptions.length; i++) {
            monthOptions[i].isdisabled = true;
            if(monthOptions[i].value>=0) {
                if(scorecards.find(element => moment(element.teeTime).month() === monthOptions[i].value)) {
                    monthOptions[i].isdisabled = false;
                }
            }
            else {
                monthOptions[i].isdisabled = false;
            }
        }
        scorecards = Stats.filterScorecards(this.state.allScorecards, this.yearSelection, this.monthSelection, "All");
        for(let i = 0; i < courseOptions.length; i++) {
            courseOptions[i].isdisabled = true;
            if(courseOptions[i].value !== "All") {
                if(scorecards.find(element => element.course === courseOptions[i].value)) {
                    courseOptions[i].isdisabled = false;
                }
            }
            else {
                courseOptions[i].isdisabled = false;
            }
        }

        this.setState({
            yearOptions: yearOptions.concat([]),
            courseOptions: courseOptions.concat([]),
            monthOptions: monthOptions.concat([]),
        });
    }

    getYearArray() {
        let yearsArray: Array<number> = [];
        for (const scoreCard of this.selectedScorecards) {
            const year = moment(scoreCard.teeTime).year();
            if(!yearsArray.includes(year)) {
                yearsArray.push(year);
            }
        }
        yearsArray.sort((a,b) => a-b);

        let missingYearsArray: Array<number> = [];
        for (let i = yearsArray[0]; i < yearsArray[yearsArray.length-1]; i++) {
            if(!yearsArray.includes(i)) {
                missingYearsArray.push(i);
            }
        }

        let yearOptions: Array<any> = this.state.yearOptions;
        for(let i = 0; i < yearsArray.length; i++) {
            yearOptions.push({
                value: yearsArray[i],
                label: yearsArray[i].toString(),
                isdisabled: false
            });
        }
        for(let i = 0; i < missingYearsArray.length; i++) {
            yearOptions.push({
                value: missingYearsArray[i],
                label: missingYearsArray[i].toString(),
                isdisabled: true,
            });
        }
        yearOptions.sort((a,b) => a.value - b.value);
        yearOptions.sort((a,b) => a === -1 ? -1 : b === -1 ? 1 : 0 );

        return yearOptions;
    }

    getMonthArray() {
        let monthsArray: Array<number> = [];
        for (const scoreCard of this.selectedScorecards) {
            const month = moment(scoreCard.teeTime).month();
            if(!monthsArray.includes(month)) {
                monthsArray.push(month);
            }
        }
        monthsArray.sort((a,b) => a-b);
        let missingMonthsArray: Array<number> = [];
        for (let i = 0; i < 12; i++) {
            if(!monthsArray.includes(i)) {
                missingMonthsArray.push(i);
            }
        }
        let monthOptions: Array<any> = this.state.monthOptions;
        for(let i = 0; i < monthsArray.length; i++) {
            monthOptions.push({
                value: monthsArray[i],
                label: moment.months(monthsArray[i]),
                isdisabled: false
            });
        }
        for(let i = 0; i < missingMonthsArray.length; i++) {
            monthOptions.push({
                value: missingMonthsArray[i],
                label: moment.months(missingMonthsArray[i]),
                isdisabled: true,
            });
        }
        monthOptions.sort((a,b) => a.value - b.value);
        monthOptions.sort((a,b) => a === -1 ? -1 : b === -1 ? 1 : 0 );

        return monthOptions;
    }

    getCourseArray() {
        let courseArray: Array<string> = [];
        for (const scoreCard of this.selectedScorecards) {
            const course = scoreCard.course;
            if(!courseArray.includes(course)) {
                courseArray.push(course);
            }
        }
        let courseOptions: Array<any> = this.state.courseOptions;
        for(let i = 0; i < courseArray.length; i++) {
            courseOptions.push({
                value: courseArray[i].toString(),
                label: courseArray[i].toString(),
                isdisabled: false
            });
        }
        courseOptions.sort((a,b) => a.value - b.value);
        courseOptions.sort((a,b) => a === "All" ? -1 : b === "All" ? 1 : 0 );

        return courseOptions;
    }

    componentDidMount() {

        const apiName = 'shankapi'; // TODO: move this outside class
        const path = `/getScorecardsForPlayer/${this.props.match.params.playerName}`;
        const myInit = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false // OPTIONAL (return the entire Axios response object instead of only response.data)
        };

        API
        .get(apiName, path, myInit)
        .then(response => {
            let allScorecards = response.map((d: any) => new ScorecardModel(d));
            this.selectedScorecards = allScorecards;
            this.previousScorecards = [];

            this.setState({
                yearOptions: this.getYearArray(),
                courseOptions: this.getCourseArray(),
                monthOptions: this.getMonthArray(),
                allScorecards: allScorecards,
            });   
        })
        .catch(error => {
            console.log(error.response);
        });
    }

    render() {
        let scorecards = this.selectedScorecards
            .sort((a,b) => moment(b.teeTime).diff(moment(a.teeTime)))
            .map(scorecard => {
                return <div key={scorecard.getUniqueKey()} className="scorecardContainer"><Scorecard scorecard={scorecard} /></div>;
            });  ;

        return (
            <div className="Content">
                <div className="wrapper">
                    <h3 id="player_name">{this.props.match.params.playerName}</h3>
                </div>
                <div className = "wrapper">
                    <Select 
                        className="select-dropdown"  
                        placeholder = {"All"} 
                        options = {this.state.yearOptions} 
                        isOptionDisabled={(option) => option.isdisabled}
                        onChange = {this.yearSelected}
                    />
                    <Select  
                        className="select-dropdown" 
                        placeholder = {"All"} 
                        options = {this.state.monthOptions}
                        isOptionDisabled={(option) => option.isdisabled}
                        onChange = {this.monthSelected}
                    />
                    <Select  
                        className="select-dropdown"
                        placeholder = {"All"}
                        options = {this.state.courseOptions}
                        isOptionDisabled={(option) => option.isdisabled}
                        onChange = {this.courseSelected}
                    />
                </div>
                <div>
                    <h3>Player Stats</h3>
                </div>
                <div className="player_stats">
                <RoundsCard 
                    player={this.props.match.params.playerName} 
                    selectedScorecards={this.selectedScorecards}
                    previousScorecards={this.previousScorecards}
                    propsId={"X" + this.yearSelection.toString()+"-"+this.monthSelection.toString()+"-"+this.courseSelection+"-"+this.selectedScorecards.length.toString()+"-"+this.previousScorecards.length.toString()}
                />
                <TotalScoreCard 
                    player={this.props.match.params.playerName} 
                    selectedScorecards={this.selectedScorecards}
                    previousScorecards={this.previousScorecards}
                    propsId={"X" + this.yearSelection.toString()+"-"+this.monthSelection.toString()+"-"+this.courseSelection+"-"+this.selectedScorecards.length.toString()+"-"+this.previousScorecards.length.toString()}
                />
                <HoleScoreCard 
                    player={this.props.match.params.playerName} 
                    selectedScorecards={this.selectedScorecards}
                    previousScorecards={this.previousScorecards}
                    propsId={"X" + this.yearSelection.toString()+"-"+this.monthSelection.toString()+"-"+this.courseSelection+"-"+this.selectedScorecards.length.toString()+"-"+this.previousScorecards.length.toString()}
                />
                <HolePctCard 
                    player={this.props.match.params.playerName} 
                    selectedScorecards={this.selectedScorecards}
                    previousScorecards={this.previousScorecards}
                    propsId={"X" + this.yearSelection.toString()+"-"+this.monthSelection.toString()+"-"+this.courseSelection+"-"+this.selectedScorecards.length.toString()+"-"+this.previousScorecards.length.toString()}
                />
                <ChokeCard 
                    player={this.props.match.params.playerName} 
                    selectedScorecards={this.selectedScorecards}
                    previousScorecards={this.previousScorecards}
                    propsId={"X" + this.yearSelection.toString()+"-"+this.monthSelection.toString()+"-"+this.courseSelection+"-"+this.selectedScorecards.length.toString()+"-"+this.previousScorecards.length.toString()}
                />
                </div>
                <div>
                    <h3>Recent Rounds</h3>
                </div>
                {scorecards}
            </div>
        );
    }
}

export default Player;