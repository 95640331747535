import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment'
import 'components/scorecardMini/scorecardMini.css';
import { Link } from "react-router-dom";
import ScorecardModel from 'model/scorecardModel'

interface AppProps {
    scorecard: ScorecardModel;
    place: Number
}
 
interface AppState { }

class ScorecardMini extends React.Component<AppProps, AppState> {
    constructor(props: any){
        super(props);
    }

    getPlaceText() {
        let placeText = "";
        if (this.props.place === 1) {
            placeText = "firstPlace";
        }
        else if (this.props.place === 2) {
            placeText = "secondPlace";
        }
        else if (this.props.place === 3) {
            placeText = "thirdPlace";
        }
        else {
            placeText = "otherPlace"
        }
        return placeText;
    }

    render() {
        return (           
            <div className="scorecardMini">
                <div className="d-flex flex-row justify-content-between">
                    <div className={"indicator " + this.getPlaceText()}></div>
                    <div className="player">
                        <Link to={{pathname: `/player/${this.props.scorecard.player}`}}>{this.props.scorecard.getScorecardDisplayName()}</Link>
                    </div>
                    <div className="teeTime">
                        {moment(this.props.scorecard.teeTime).format('M/D/YY')}
                    </div>
                    <div className="length">
                        {this.props.scorecard.length}
                    </div>
                    <div className="score">
                        <h4>{this.props.scorecard.totalScore}</h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScorecardMini;