import React from "react";
import Scorecard from 'components/scorecard/scorecard';
import ScorecardModel from 'model/scorecardModel'
import axios from 'axios';
import { API } from 'aws-amplify';

interface AppProps { }
 
interface AppState {
    scorecards: Array<ScorecardModel>;
}

class Leaderboard extends React.Component<AppProps, AppState> {
    constructor(props: any){
        super(props);
        this.state = { scorecards: Array<ScorecardModel>() }
    }

    componentDidMount() {
        const apiName = 'shankapi';
        const path = '/getLeaderboard'; 
        const myInit = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false // OPTIONAL (return the entire Axios response object instead of only response.data)
        };

        API
        .get(apiName, path, myInit)
        .then(response => {
            this.setState({ scorecards: response.map((d: any) => new ScorecardModel(d)) })
        })
        .catch(error => {
            console.log(error.response);
        });

        /*
        axios.get('/leaderboard')
        .then(response => response.data)
        .then(
            highScores => {
                this.setState({ scorecards: highScores.map((d: any) => new ScorecardModel(d)) })
            }
        );*/
    }

    render() {
        return (
            <div className="Content">
                <h3>Leaderboard</h3>
                <p>Top 10 scores of all time</p>
                {this.state.scorecards
                    .sort((a,b) => a.totalScore - b.totalScore)
                    .map(scorecard => {
                    return <div key={scorecard.getUniqueKey()} className="scorecardContainer"><Scorecard scorecard={scorecard} /></div>;
                })}
            </div>
        );
    }
}

export default Leaderboard;