import React from 'react';
import 'components/addScorecard/addScorecard.css';  
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';
import { Redirect } from "react-router-dom";

class Login extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            password: '',
            username: '',
            errorMessage: '',
            unconfirmed: false,
            confirmCode: ''
        };
  
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.login = this.login.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirmCodeChange = this.handleConfirmCodeChange.bind(this);
    }

    handleUsernameChange(event) {
        this.setState({
            username: event.target.value
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    handleConfirmCodeChange(event) {
        this.setState({
            confirmCode: event.target.value
        });
    }

    async login() {
        try {
            const user = await Auth.signIn(this.state.username, this.state.password);
            console.log(user);
            localStorage.setItem("username", user.username);
            localStorage.setItem("displayName", user.attributes.name);
            window.location.reload();
        } catch (error) {
            if (error.name == "UserNotConfirmedException") {
                this.setState({
                    unconfirmed: true
                });
            }
            else {
                this.setState({
                    errorMessage: error.message
                });
            }
            console.log('Error signing in', error);
        }
    }

    async confirm() {
        try {
            const user = await Auth.confirmSignUp(this.state.username, this.state.confirmCode);
            this.login();
        } catch (error) {
            this.setState({
                errorMessage: error.message
            });
            console.log('Error confirming', error);
        }
    }
    
    handleSubmit(event) {
        if (this.state.unconfirmed) {
            this.confirm();
        }
        else {
            this.login();
        }
        event.preventDefault();
    }

    render() {
        if (localStorage.getItem('isUserLoggedIn') == 'true') {
            this.props.history.push('/home');
        }
        return (
            <Card>
                <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={this.state.username} onChange={this.handleUsernameChange} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={this.state.password} onChange={this.handlePasswordChange} />
                        </Form.Group> 
                    </Form.Row>
                    {this.state.unconfirmed && 
                        <Form.Row>
                            <Form.Group as={Col} controlId="formConfirm">
                                <Form.Label>Confirmation code</Form.Label>
                                <Form.Control value={this.state.confirmCode} onChange={this.handleConfirmCodeChange} />
                            </Form.Group> 
                        </Form.Row>
                    } 
                    {this.state.errorMessage != '' && 
                        <Form.Row>
                            <Form.Group as={Col} controlId="formError">
                                <Form.Label>{this.state.errorMessage}</Form.Label>
                            </Form.Group> 
                        </Form.Row>
                    }                     
                    <Button variant="primary" onClick={this.handleSubmit}>
                        Submit
                    </Button>
                </Form>
                </Card.Body>
            </Card>
        );
    }
}

export default Login;