import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import 'components/player/cards/holescorecard.css';  
import Stats from 'helpers/stats';
import ScorecardModel from "model/scorecardModel";
import { Carousel } from "react-bootstrap";

const GRAPH_Y_TITLE = "Score";
const GRAPH_X_TITLE = "Hole Count"

const TREND_COLORS = {
    GOOD: "#008000",
    BAD: "#C00000",
};

const BUTTON_TEXT = {
    TEXT: "Chart",
    CHART: "Return",
};

const SERIES_NAMES = {
    PAR3: "Par 3 Trend",
    PAR4: "Par 4 Trend",
    PAR5: "Par 5 Trend",
};

const CARD_WIDTHS = {
    TEXT: "",
    CHART: "100%",
};

interface AppProps { 
    player: string;
    propsId: string;
    selectedScorecards: Array<ScorecardModel>;
    previousScorecards: Array<ScorecardModel>;
}
 
interface AppState {
    displayExpanded: boolean;
}

class HoleScoreCard extends React.Component<AppProps, AppState> {

    stats : {
        scorePar3: string;
        scorePar4: string;
        scorePar5: string;
    }
    trends : {
        scorePar3: string;
        scorePar4: string;
        scorePar5: string;
    }
    trendColors : {
        par3: string;
        par4: string;
        par5: string;
    };
    propsOldId: string;
    chartOptions: any;
    width: string;
    button_text: string;

    constructor(props: any){
        super(props);

        this.propsOldId = "";
        this.width = "";
        this.button_text = BUTTON_TEXT.TEXT
        this.stats = {
            scorePar3: "",
            scorePar4: "",
            scorePar5: "",
        }
        this.trends = {
            scorePar3: "",
            scorePar4: "",
            scorePar5: "",
        }
        this.trendColors = {
            par3: "",
            par4: "",
            par5: "",
        };
        this.chartOptions = {
            title: {
                text: "",
                floating: true,
            },
            series: [
                { name: SERIES_NAMES.PAR3, data: [], type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.PAR4, data: [], type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.PAR5, data: [], type: 'spline', marker: { enabled: false } },
            ],
            legend: {
                enabled: true,
                verticalAlign: "top",
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: GRAPH_Y_TITLE
                }     
            },
            xAxis: {
                title: {
                    text: GRAPH_X_TITLE
                },
            }
        };

        this.state = {
            displayExpanded: false,
        }
        this.selectDisplay = this.selectDisplay.bind(this);
    } 

    selectDisplay() {
        if(this.state.displayExpanded) {
            this.width = CARD_WIDTHS.TEXT;
            this.button_text = BUTTON_TEXT.TEXT;
        } else {
            this.width = CARD_WIDTHS.CHART;
            this.button_text = BUTTON_TEXT.CHART;
        }
        this.setState({
            displayExpanded: !this.state.displayExpanded,
        });

    }

    calculateStats() {
        let scorePar3: number;
        let scorePar4: number;
        let scorePar5: number;
        let scorePar3_super: number;
        let scorePar4_super: number;
        let scorePar5_super: number;
        let selected_count: number;
        let previous_count: number;

        //get averages for stats
        scorePar3 = 
        Stats.getAverage(
        Stats.getHoleScoresByPar(this.props.selectedScorecards,3));
        scorePar3_super = scorePar3 -
            Stats.getAverage(
            Stats.getHoleScoresByPar(this.props.previousScorecards,3));
        scorePar4 = 
            Stats.getAverage(
            Stats.getHoleScoresByPar(this.props.selectedScorecards,4));
        scorePar4_super = scorePar4 -
            Stats.getAverage(
            Stats.getHoleScoresByPar(this.props.previousScorecards,4));
        scorePar5 = 
            Stats.getAverage(
            Stats.getHoleScoresByPar(this.props.selectedScorecards,5));
        scorePar5_super = scorePar5 -
            Stats.getAverage(
            Stats.getHoleScoresByPar(this.props.previousScorecards,5));

        //get total counts, assumes 1 valid round will contain at least 1 par 3, par 4, par 5
        selected_count = Stats.getHoleScoresByPar(this.props.selectedScorecards, 4).length;
        previous_count = Stats.getHoleScoresByPar(this.props.previousScorecards, 4).length;

        //if count is zero, then no current valid scores were found. put default message instead.
        //else use calculated values
        if(selected_count === 0) {
            const message = "N/A";
            this.stats.scorePar3 = message;
            this.stats.scorePar4 = message;
            this.stats.scorePar4 = message;
        } else {
            this.stats.scorePar3 = scorePar3.toFixed(1);
            this.stats.scorePar4 = scorePar4.toFixed(1);
            this.stats.scorePar5 = scorePar5.toFixed(1);
        }

        //if either count is zero, then do not show trending value.
        //else show trending data
        if(selected_count === 0 || previous_count === 0) {
            const message = "";
            this.trends.scorePar3 = message;
            this.trends.scorePar4 = message;
            this.trends.scorePar5 = message;
        } else {

            if(scorePar3_super <= 0) { 
                this.trends.scorePar3 = "\u2193" + Math.abs(scorePar3_super).toFixed(1);
                this.trendColors.par3 = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.scorePar3 = "\u2191" + scorePar3_super.toFixed(1);
                this.trendColors.par3 = TREND_COLORS.BAD;
            }

            if(scorePar4_super <= 0) { 
                this.trends.scorePar4 = "\u2193" + Math.abs(scorePar4_super).toFixed(1);
                this.trendColors.par4 = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.scorePar4 = "\u2191" + scorePar4_super.toFixed(1);
                this.trendColors.par4 = TREND_COLORS.BAD;
            }

            if(scorePar5_super <= 0) { 
                this.trends.scorePar5 = "\u2193" + Math.abs(scorePar5_super).toFixed(1);
                this.trendColors.par5 = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.scorePar5 = "\u2191" + scorePar5_super.toFixed(1);
                this.trendColors.par5 = TREND_COLORS.BAD;
            }
        }
    }

    calculateGraphs() {
        var DataPar3;
        var DataPar4;
        var DataPar5;
        var smoothDataPar3;
        var smoothDataPar4;
        var smoothDataPar5;

        DataPar3 = Stats.getHoleScoresByPar(this.props.selectedScorecards,3);
        DataPar4 = Stats.getHoleScoresByPar(this.props.selectedScorecards,4);
        DataPar5 = Stats.getHoleScoresByPar(this.props.selectedScorecards,5);


        smoothDataPar3 = Stats.getSmoothScores(DataPar3).map(x => Math.round(x*100)/100);
        smoothDataPar4 = Stats.getSmoothScores(DataPar4).map(x => Math.round(x*100)/100);
        smoothDataPar5 = Stats.getSmoothScores(DataPar5).map(x => Math.round(x*100)/100);
        
        this.chartOptions = {
            title: {
                text: "",
                floating: true,
            },
            series: [
                { name: SERIES_NAMES.PAR3, data: smoothDataPar3, type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.PAR4, data: smoothDataPar4, type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.PAR5, data: smoothDataPar5, type: 'spline', marker: { enabled: false } },
            ],
            legend: {
                enabled: true,
                verticalAlign: "top",
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: GRAPH_Y_TITLE
                }     
            },
            xAxis: {
                title: {
                    text: GRAPH_X_TITLE
                },
            }
        };
    }

    render() {
        if(this.props.propsId !== this.propsOldId) { //year, month, course filter changed
            this.calculateGraphs();
            this.calculateStats();
            this.propsOldId = this.props.propsId;
        }
        return (
            <div className="wrapper" style={{ width: this.width }}>
                <Card className="card">
                    <Card.Title className="card_title">Hole Averages</Card.Title>
                    {this.state.displayExpanded && <div className="chart">
                        <HighchartsReact
                                highcharts={Highcharts}
                                options={this.chartOptions}
                        />
                    </div>}
                    {!this.state.displayExpanded && <div className="text">
                        <Carousel className="carousel" indicators={false} interval={5000}>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.scorePar3}</p>
                                    <h1 className="value">{this.stats.scorePar3}</h1>
                                    <p className="trend" style={{color: this.trendColors.par3}}>{this.trends.scorePar3}</p>
                                </div>
                                <p className="description">Par 3s</p>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.scorePar4}</p>
                                    <h1 className="value">{this.stats.scorePar4}</h1>
                                    <p className="trend" style={{color: this.trendColors.par4}}>{this.trends.scorePar4}</p>
                                </div>
                                <p className="description">Par 4s</p>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.scorePar5}</p>
                                    <h1 className="value">{this.stats.scorePar5}</h1>
                                    <p className="trend" style={{color: this.trendColors.par5}}>{this.trends.scorePar5}</p>
                                </div>
                                <p className="description">Par 5s</p>
                            </Carousel.Item>
                        </Carousel>
                    </div> }
                    <Button className="button" variant="link" onClick = {this.selectDisplay} >{this.button_text}</Button>
                </Card>                         
            </div>
        );
    }
}

export default HoleScoreCard;