class ScoreForHole {
    readonly holeNumber: number;
    readonly score: number;
    readonly scoreToPar: number;
    constructor(hn: string, sc: string, stp: string) {
        this.holeNumber = parseInt(hn);
        this.score = parseInt(sc);
        this.scoreToPar = parseInt(stp);
    }
    public getScoreText() {
        let scoreText = "";
        if (this.scoreToPar === 0) {
            scoreText = "par";
        }
        else if (this.scoreToPar === -1) {
            scoreText = "birdie";
        }
        else if (this.scoreToPar === 1) {
            scoreText = "bogey";
        }
        else if (this.scoreToPar < -1) {
            scoreText = "eagle";
        }
        else {
            scoreText = "double"
        }
        return scoreText;
    }
    public getScoreToParString() {
        if (this.scoreToPar == 0) {
            return "E"
        }
        else if (this.scoreToPar > 0) {
            return "+" + this.scoreToPar;
        }
        return this.scoreToPar;
    }
}

export default ScoreForHole;