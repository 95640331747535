import React from 'react';
import 'components/addScorecard/addScorecard.css';  
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Autocomplete from 'react-autocomplete';
import moment from 'moment';
import axios from 'axios';
import { API } from 'aws-amplify';

class AddScorecard extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            name: localStorage.getItem('username'),
            course: '',
            city: '',
            state: '',
            scorecard: '',
            teeTime: '',
            date: moment().format(),
            numHolesPlayed: 0,
            totalScore: 0,
            length: 0,
            scorecardArray: [],
            startingHole: 1,
            courseOptions: [],
            selectedCourseData: null,
            teesToSelectFrom: [],
            showCityStateModal: false,
            manualEntry: false
        };
  
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCourseChange = this.handleCourseChange.bind(this);
        this.handleScorecardChange = this.handleScorecardChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTeeTimeChange = this.handleTeeTimeChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLengthChange = this.handleLengthChange.bind(this);
        this.handleStartingHoleChange = this.handleStartingHoleChange.bind(this);
        this.handleComboBoxLengthChange = this.handleComboBoxLengthChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value
        });
    }

    getCourse(courseName, city, state) {
        const apiName = 'shankapi';
        const path = `/course/${courseName}/${city}/${state}`; 
        const myInit = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false // OPTIONAL (return the entire Axios response object instead of only response.data)
        };

        API
        .get(apiName, path, myInit)
        .then(response => {
            console.log(response);
            this.setTeesFromCourseMetaData(response.metadata);
            this.setCityStateFromCourseMetaData(response.metadata);
            this.setState({ selectedCourseData: response.metadata });
        });
    }

    setTeesFromCourseMetaData(metadata) {
        let tees = metadata.filter(courseData => courseData.sk.startsWith('TEE#'));
        let teesToSelect = []
        tees.forEach(tee => teesToSelect.push(this.toCaps(tee.name) + " - " + tee.length));
        this.setState({ teesToSelectFrom: teesToSelect });
    }

    setCityStateFromCourseMetaData(metadata) {
        let md = metadata.filter(courseData => courseData.sk == 'METADATA');
        if (md.length == 1) {
            this.setState({ city: md[0].city, state: md[0].state});
        }
    }

    searchCourses(course) {
        const apiName = 'shankapi';
        const path = `/searchCoursesV2?q=${course}*`; 
        const myInit = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false
        };

        API
        .get(apiName, path, myInit)
        .then(response => {
            console.log(response);
            this.setState({
                courseOptions: response.hits.hit.map(x => x.fields)
            });           
        })
    }

    handleLengthChange(event) {
        this.setState({
            length: event.target.value
        });
    }

    handleComboBoxLengthChange(event) {
        this.setState({
            length: parseInt(event.target.value.split(' - ')[1], 10)
        });   
    }

    handleScorecardChange(event) { 
        var scoreInput = event.target.value;

        // only auto add space if input length is greater than stored length
        // otherwise, user is deleting characters
        if (scoreInput.length > this.state.scorecard.length) {
            scoreInput += " ";
        }

        var scoreIntArray = scoreInput.trim().split(/[\s]/).map(function(e) { 
            return parseInt(e);
        });

        var total = 0;
        scoreIntArray.forEach(element => {
            total += element;
        });

        this.setState({
            scorecard: scoreInput,
            numHolesPlayed: scoreIntArray.length,
            totalScore: total,
            scorecardArray: scoreIntArray
        });
    }

    handleDateChange(event) {
        this.setState({
            date: event.target.value
        });
    }

    handleTeeTimeChange(event) {
        this.setState({
            teeTime: event.target.value
        });
    }

    handleStartingHoleChange(event) {
        this.setState({
            startingHole: event.target.value
        });
    }

    toCaps(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    async submitScorecardToServer() {

        const apiName = 'shankapi';
        const path = '/createScorecard'; 
        const myInit = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: true,
            body: { 
                player: this.state.name,
                displayName: localStorage.getItem('displayName'),
                course: this.state.course,
                length: this.state.length,
                teeTime: moment(this.state.date + " " + this.state.teeTime),
                scores: this.state.scorecardArray,
                startingHole: this.state.startingHole,
                totalScore: this.state.totalScore,
                holesPlayed: this.state.scorecardArray.length,
                city: this.state.city,
                state: this.state.state
            }, 
        };

        API
        .post(apiName, path, myInit)
        .then(response => {
            if (response.status === 200){
                alert('A scorecard was submitted for ' + this.state.name + ' at ' + this.state.course);
                window.location.reload();
            } else {
                console.log(response);
                alert('Error submitting scorecard:' + response.statusText);
            }
        })
        .catch(error => {
            console.log(error.response);
        });
    }
    
    handleSubmit(event) {
        event.preventDefault();

        console.log(this.state.selectedCourseData);

        // If we don't have saved course, prompt for city, state
        if (this.state.selectedCourseData == null && !this.state.manualEntry) {
            this.showModal();
            console.log(this.state.showCityStateModal);
            return;
        }

        this.submitScorecardToServer();
    }
    
    handleCourseChange(event) {
        let courseName = event.target.value;
        this.setState({
            course: courseName
        });
        if (courseName.length > 0) {
            this.searchCourses(courseName)
        }
        else {
            this.setState({
                courseOptions: []
            });
        }
    }

    showModal = () => this.setState({ showCityStateModal: true })

    closeModal = () => this.setState({ showCityStateModal: false, manualEntry: true })

    handleCityChange(event) {
        this.setState({
            city: event.target.value
        });
    }

    handleStateChange(event) {
        this.setState({
            state: event.target.value
        });
    }

    render() {
        return (
            <div>
            <Modal animation={false} show={this.state.showCityStateModal} onHide={this.closeModal}>
                <Modal.Header>Course not found</Modal.Header>
                <Modal.Body>
                    <p>{this.state.course} does not exist in Shank's database yet. Please enter the city and state and we'll upload the course info shortly.</p>
                    <Form>
                        <Form.Row>
                            <Form.Label>City</Form.Label>
                            <Form.Control required type="text" onChange={this.handleCityChange} />
                        </Form.Row>
                        <Form.Row>
                            <Form.Label>State</Form.Label>
                            <Form.Control required type="text" onChange={this.handleStateChange} />
                        </Form.Row>
                    </Form> 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.closeModal}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
            <Card>

                <Card.Body>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} lg="4" md="9" xs="12" controlId="formCourse">
                            <Form.Label>Course</Form.Label>
                            
                            <Autocomplete
                                inputProps={{ id: 'course-autocomplete', className: "form-control" }}
                                wrapperStyle={{ position: 'relative'}}
                                value={this.state.course}
                                items={this.state.courseOptions}
                                getItemValue={(item) => item.course}
                                onSelect={(course, item) => {
                                    this.setState({
                                        course: course,
                                        courseOptions: [ item ]
                                    });
                                    this.getCourse(item.course, item.city, item.state);
                                }}
                                onChange={this.handleCourseChange}
                                renderMenu={children => (
                                    <div className="menu">
                                        {children}
                                    </div>
                                )}
                                renderItem={(item, isHighlighted) => (
                                    <div className="auto-container" style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                        <div className="auto-course">{item.course}</div>
                                        <div className="auto-metadata">{item.city}, {item.state}</div>
                                    </div>
                                )}
                            />
                        </Form.Group>
                        {!this.state.teesToSelectFrom.length > 0 && <Form.Group as={Col} lg="3" md="3" xs="12" controlId="formLength">
                            <Form.Label>Tee</Form.Label>
                            <Form.Control required type="number" placeholder="Length" value={this.state.length != 0 ? this.state.length : null} onChange={this.handleLengthChange} />
                        </Form.Group>}
                        {this.state.teesToSelectFrom.length > 0 && <Form.Group as={Col} lg="3" md="3" xs="12" controlId="formLength">
                            <Form.Label>Tee</Form.Label>
                            <Form.Control as="select" onChange={this.handleComboBoxLengthChange}>
                                {this.state.teesToSelectFrom.map(tee => <option>{tee}</option>)}
                            </Form.Control>
                        </Form.Group>}
                        <Form.Group as={Col} lg="auto" md="6" xs="6"controlId="formDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control required type="date" value={this.state.date} onChange={this.handleDateChange} />
                        </Form.Group>
                        <Form.Group as={Col} lg="auto" md="6" xs="6" controlId="formTeeTime">
                            <Form.Label>Tee time</Form.Label>
                            <Form.Control required type="time" value={this.state.teeTime} onChange={this.handleTeeTimeChange} />
                        </Form.Group>
                    </Form.Row>                 
                    <Form.Row>
                        <Form.Group as={Col} md="3" controlId="formName">
                            <Form.Label>Player</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>@</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="text" value={this.state.name} onChange={this.handleNameChange} disabled/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="formScorecard">
                            <Form.Label>Scorecard</Form.Label>
                            <Form.Control required type="tel" value={this.state.scorecard} onChange={this.handleScorecardChange} />
                            <Form.Text className="text-muted">
                            Enter your scores
                            </Form.Text>
                        </Form.Group>
                        <Form.Group as={Col} md="1" xs="4" controlId="formStart">
                            <Form.Label>Start</Form.Label>
                            <Form.Control required type="text" value={this.state.startingHole} onChange={this.handleStartingHoleChange}/>
                        </Form.Group>
                        <Form.Group as={Col} md="1" xs="4" controlId="formHoles">
                            <Form.Label>Holes</Form.Label>
                            <Form.Control type="text" value={this.state.numHolesPlayed} disabled/>
                        </Form.Group>
                        <Form.Group as={Col} md="1" xs="4" controlId="formScore">
                            <Form.Label>Score</Form.Label>
                            <Form.Control type="text" value={this.state.totalScore} disabled/>
                        </Form.Group>
                    </Form.Row>                 
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                </Card.Body>
            </Card>
            </div>
        );
    }
}

export default AddScorecard;