/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "shankapi",
            "endpoint": "https://26uuy5btyk.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "shankdb-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:a9263044-4a52-495d-a24b-db6723677757",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_D4yQyWnf1",
    "aws_user_pools_web_client_id": "1uhpijf67b6jk6deee491vqqee",
    "oauth": {}
};


export default awsmobile;
