import ScorecardModel from "model/scorecardModel";
import moment from 'moment'

class Stats {
    private static getMovingAverage(data: Array<number>) {
        var newData: Array<number> = new Array<number>();
        const exp_alpha = .8;
        const exp_gamma = .2; 
        if (data.length < 2) {
            return data;
        }
        newData.push(data[0]);
        for(var i=1; i<data.length; i++) {
            newData[i] = newData[i-1]*exp_alpha + data[i]*exp_gamma;
        }
        return newData;
    }

    static getAverage(data: Array<number>) {
        if(data.length < 1) {
            return 0;
        }
        let average = 0;
        for(let i = 0; i < data.length; i++) {
            average += data[i]
        }
        return average / data.length;
    }

    static getSmoothScores(data: Array<number>) {
        if(data.length < 1) {
            return data; 
        } 
        return this.getMovingAverage(data);
    }

    static getTypicalScore(smoothedData: Array<number>) {
        if(smoothedData.length < 1) {
            return 0;
        }  
        return smoothedData[smoothedData.length-1];
    }

    static getTotalScores(scoreCards: Array<ScorecardModel>) {
        return scoreCards
            .filter((scorecard: ScorecardModel) => Number(scorecard.holesPlayed) === 18)
            .filter((scorecard: ScorecardModel) => Boolean(scorecard.hasCourseData) === true)
            .sort((a,b) => moment(a.teeTime).diff(moment(b.teeTime)))
            .map((scorecard: ScorecardModel) => scorecard.totalScore).map(Number);
    }

    static getDates(scoreCards: Array<ScorecardModel>) {
        return scoreCards
            .filter((scorecard: ScorecardModel) => Number(scorecard.holesPlayed) === 18)
            .filter((scorecard: ScorecardModel) => Boolean(scorecard.hasCourseData) === true)
            .sort((a,b) => moment(a.teeTime).diff(moment(b.teeTime)))
            .map((scorecard: ScorecardModel) => moment(scorecard.teeTime).valueOf()).map(Number);
    }

    static getScoreToPars(scoreCards: Array<ScorecardModel>) {
        return scoreCards
            .filter((scorecard: ScorecardModel) => Number(scorecard.holesPlayed) === 18)
            .filter((scorecard: ScorecardModel) => Boolean(scorecard.hasCourseData) === true)
            .sort((a,b) => moment(a.teeTime).diff(moment(b.teeTime)))
            .map((scorecard: ScorecardModel) => scorecard.totalScoreToPar).map(Number);
    }

    static getHoleScoresByPar(scoreCards: Array<ScorecardModel>, par: Number) {
        const filteredScoreCards = scoreCards
            .filter((scorecard: ScorecardModel) => Number(scorecard.holesPlayed) === 18)
            .filter((scorecard: ScorecardModel) => Boolean(scorecard.hasCourseData) === true)
            .sort((a,b) => moment(a.teeTime).diff(moment(b.teeTime)));
        var holeData : Array<number> = new Array<number>();
        for (const scoreCard of filteredScoreCards) {
            for (const scoreForHole of scoreCard.scoresForHole) {
                if(scoreForHole.score - scoreForHole.scoreToPar === par) {
                    holeData.push(scoreForHole.score);
                }
            }
        }
        return holeData;
    }

    static getChokeRating(scoreCard: ScorecardModel) {
        const startHole = Number(scoreCard.startingHole);
        const chokeHoles = [
            (startHole + 15 > 18 ? startHole - 3 : startHole + 15),
            (startHole + 16 > 18 ? startHole - 2 : startHole + 16),
            (startHole + 17 > 18 ? startHole - 1 : startHole + 17),
        ]
        var normalHoleScoreToPar  = 0;
        var chokeHoleScoreToPar = 0;
        for (const scoreForHole of scoreCard.scoresForHole) {
            if(scoreForHole.holeNumber === chokeHoles[0] || scoreForHole.holeNumber === chokeHoles[1] || scoreForHole.holeNumber === chokeHoles[2]) {
                chokeHoleScoreToPar += scoreForHole.scoreToPar;
            }
            else normalHoleScoreToPar += scoreForHole.scoreToPar;
        }
        return (chokeHoleScoreToPar + normalHoleScoreToPar)-((normalHoleScoreToPar*18)/15);
    }

    static getChokeRatings(scoreCards: Array<ScorecardModel>) { //0 is neutral, - is clutch, + is choke
        const filteredScoreCards = scoreCards
            .filter((scorecard: ScorecardModel) => Number(scorecard.holesPlayed) === 18)
            .filter((scorecard: ScorecardModel) => Boolean(scorecard.hasCourseData) === true)
            .sort((a,b) => moment(a.teeTime).diff(moment(b.teeTime)));
        var chokeScore: Array<number> = new Array<number>();
        for (const scoreCard of filteredScoreCards) {
            chokeScore.push(this.getChokeRating(scoreCard));
        }
        return chokeScore;
    }

    static getScoreToParPercentages(scoreCards: Array<ScorecardModel>, scoreToPar: Number) {
        const filteredScoreCards = scoreCards
        .filter((scorecard: ScorecardModel) => Number(scorecard.holesPlayed) === 18)
        .filter((scorecard: ScorecardModel) => Boolean(scorecard.hasCourseData) === true)
        .sort((a,b) => moment(a.teeTime).diff(moment(b.teeTime)));
        var percentageData : Array<number> = new Array<number>();
        for (const scoreCard of filteredScoreCards) {
            let count = 0;
            for (const scoreForHole of scoreCard.scoresForHole) {
                if(scoreForHole.scoreToPar === scoreToPar) {
                    count++;
                }
            }
            percentageData.push(100* count/Number(scoreCard.holesPlayed));
        }
        return percentageData;
    }

    static getGreaterThanScoreToParPercentages(scoreCards: Array<ScorecardModel>, scoreToPar: Number) {
        const filteredScoreCards = scoreCards
        .filter((scorecard: ScorecardModel) => Number(scorecard.holesPlayed) === 18)
        .filter((scorecard: ScorecardModel) => Boolean(scorecard.hasCourseData) === true)
        .sort((a,b) => moment(a.teeTime).diff(moment(b.teeTime)));
        var percentageData : Array<number> = new Array<number>();
        for (const scoreCard of filteredScoreCards) {
            let count = 0;
            for (const scoreForHole of scoreCard.scoresForHole) {
                if(scoreForHole.scoreToPar >= scoreToPar) {
                    count++;
                }
            }
            percentageData.push(100* count/Number(scoreCard.holesPlayed));
        }
        return percentageData;
    }

    static filterScorecards(scoreCards: Array<ScorecardModel>, year: number, month: number, course: string) {
        let filteredScoreCards = scoreCards;
        if(year > 0) {
            filteredScoreCards = filteredScoreCards.filter((scorecard: ScorecardModel) => moment(scorecard.teeTime).year() === year);
        }
        if(month >= 0) { //starts at 0, 0 is january
            filteredScoreCards = filteredScoreCards.filter((scorecard: ScorecardModel) => moment(scorecard.teeTime).month() === month);
        }
        if(course !== "All") {
            filteredScoreCards = filteredScoreCards.filter((scorecard: ScorecardModel) => scorecard.course === course);
        }
        return filteredScoreCards;
    }
}

export default Stats;