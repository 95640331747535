import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import 'components/player/cards/roundscard.css';  
import Stats from 'helpers/stats';
import ScorecardModel from "model/scorecardModel";

interface AppProps { 
    player: string;
    propsId: string;
    selectedScorecards: Array<ScorecardModel>;
    previousScorecards: Array<ScorecardModel>;
}
 
interface AppState {
}

class RoundsCard extends React.Component<AppProps, AppState> {

    stats : {
        roundsPlayed: string;
    }
    trends : {
        roundsPlayed: string;
    }
    propsOldId: string;

    constructor(props: any){
        super(props);

        this.propsOldId = "";
        this.stats = {
            roundsPlayed: "",
        };
        this.trends = {
            roundsPlayed: "",
        };
    } 

    calculateStats() {
        let roundsPlayed: number;        
        let roundsPlayed_super: number;

        roundsPlayed = Stats.getTotalScores(this.props.selectedScorecards).length;
        roundsPlayed_super = roundsPlayed - Stats.getTotalScores(this.props.previousScorecards).length;

        this.stats.roundsPlayed = roundsPlayed.toFixed(0);

        if(this.props.propsId.includes("X-1-")) {this.trends.roundsPlayed = "";}
        else {
            if(roundsPlayed_super <= 0) { this.trends.roundsPlayed = "\u2193" + Math.abs(roundsPlayed_super).toFixed(0);}
            else {this.trends.roundsPlayed = "\u2191" + roundsPlayed_super.toFixed(0); }
        }
    }

    render() {
        if(this.props.propsId !== this.propsOldId) { //year, month, course filter changed
            this.calculateStats();
            this.propsOldId = this.props.propsId;
        }
        return (
            <div className="wrapper">
                <Card className="card">
                    <Card.Title className="card_title">Rounds Played</Card.Title>
                    <div className="text">
                        <div className="content">
                            <p className="trend" style={{visibility: "hidden"}}>{this.trends.roundsPlayed}</p>
                            <h1 className="value">{this.stats.roundsPlayed}</h1>
                            <p className="trend">{this.trends.roundsPlayed}</p>
                        </div>
                        <p className="description">Total Rounds</p>
                        <Button className="button" variant="link" style={{visibility: "hidden"}}>dummy</Button>
                    </div> 
                </Card>                         
            </div>
        );
    }
}

export default RoundsCard;