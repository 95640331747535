import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment'
import 'components/scorecard/scorecard.css';
import { Link } from "react-router-dom";
import ScorecardModel from 'model/scorecardModel'
import ScoreForHole from 'model/scoreForHole'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { MdExpandMore } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import { IconContext } from "react-icons";
import { ConsoleWriter } from 'istanbul-lib-report';
import Stats from 'helpers/stats';

interface AppProps {
    scorecard: ScorecardModel;
}
 
interface AppState { 
    showDeleteModal: boolean;
}


class Scorecard extends React.Component<AppProps, AppState> {
    constructor(props: any){
        super(props);
        this.state = {
            showDeleteModal: false
        }

        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.hideDeleteModal = this.hideDeleteModal.bind(this);
        this.deleteScorecard = this.deleteScorecard.bind(this);
    }

    getSum(arr: Array<number>) {
        return arr.reduce((a, b) => a + b, 0)
    }

    renderTooltip = (score: ScoreForHole) => (
        <Tooltip id="button-tooltip">
            Hole {score.holeNumber}: {score.getScoreToParString()}
        </Tooltip>
    );

   getHoleElement = (score: ScoreForHole, cssClass: String) => {
       if (this.props.scorecard.hasCourseData) {
            return <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip(score)} >
                <div key={score.holeNumber} className={cssClass + " " + (score.getScoreText())}>{score.score}&nbsp;</div>
            </OverlayTrigger>;
       }
       else {
            return <div key={score.holeNumber} className={"hole " + (score.getScoreText())}>{score.score}&nbsp;</div>
       }
   }

   getCourseElement() {
        if (this.props.scorecard.hasCourseData) {
            return <Link to={{pathname: `/course/${this.props.scorecard.course}/${this.props.scorecard.city}/${this.props.scorecard.state}`}}>{this.props.scorecard.course}</Link>;
        }
        else {
            return this.props.scorecard.course;
        }
    }

    getDantrapScore() {
        let totalOverPar = 0;
        this.props.scorecard.scoresForHole.filter(sc => [12,13,14].includes(sc.holeNumber)).forEach(sc => {
            totalOverPar += sc.scoreToPar;
        });
        if (totalOverPar == 0) {
            return "DT E"
        } else if (totalOverPar > 0) {
            return "DT +" + totalOverPar;
        }   else {
            return "DT " + totalOverPar;
        }
    }

    getBaseChokeRatingCssClass() {
        if (this.props.scorecard.showDantrap && this.props.scorecard.startingHole == 1) {
            return "chokeRatingDantrap"
        }
        return "chokeRating";
    }

    getChokeRatingCssClass() {
        let chokeRating = Stats.getChokeRating(this.props.scorecard);
        if (chokeRating >= 2) {
            return this.getBaseChokeRatingCssClass() + " bigChoke";
        }
        if (chokeRating <= -2) {
            return this.getBaseChokeRatingCssClass() + " bigClutch";
        }
        return this.getBaseChokeRatingCssClass() + " neutral";
    }

    getScorecard() {
        if (this.props.scorecard.scoresForHole.length > 9) {
            return <Row>
                        <div>
                            {this.props.scorecard.getFrontNine().map(score => {
                                if (this.props.scorecard.showDantrap) {
                                    if (score.holeNumber == 12) {
                                        return this.getHoleElement(score, "dt-left")
                                    }
                                    else if (score.holeNumber == 13) {
                                        return this.getHoleElement(score, "dt-middle")
                                    }
                                    else if (score.holeNumber == 14) {
                                        return this.getHoleElement(score, "dt-right")
                                    }
                                }
                                return this.getHoleElement(score, "hole");
                            })}
                            {this.props.scorecard.showDantrap && this.props.scorecard.startingHole == 10 && <div className="dantrap">{this.getDantrapScore()}</div>}
                            <div className="final">{this.getSum(this.props.scorecard.getFrontNine().map(x => x.score))}</div>
                        </div> 
                        <div className="back9">
                            {this.props.scorecard.getBackNine().map(score => {
                                if (this.props.scorecard.showDantrap) {
                                    if (score.holeNumber == 12) {
                                        return this.getHoleElement(score, "dt-left")
                                    }
                                    else if (score.holeNumber == 13) {
                                        return this.getHoleElement(score, "dt-middle")
                                    }
                                    else if (score.holeNumber == 14) {
                                        return this.getHoleElement(score, "dt-right")
                                    }
                                }
                                return this.getHoleElement(score, "hole");
                            })}
                            {this.props.scorecard.showDantrap && this.props.scorecard.startingHole == 1 && <div className="dantrap">{this.getDantrapScore()}</div>}
                            <div className={this.getChokeRatingCssClass()}></div>         
                            <div className="final">{this.getSum(this.props.scorecard.getBackNine().map(x => x.score))}</div>
                        </div> 
                        <div className="total">{this.props.scorecard.totalScore}</div>
                    </Row>;
        }
        return <Row>
                    <div>
                        {this.props.scorecard.getFrontNine().map(score => {
                            return this.getHoleElement(score, "hole");
                        })}
                        <div className="total">{this.getSum(this.props.scorecard.getFrontNine().map(x => x.score))}</div>
                    </div>
                </Row>;
    }

    showDeleteModal() {
        this.setState({
            showDeleteModal: true
        });
    }

    hideDeleteModal() {
        this.setState({
            showDeleteModal: false
        });
    }

    deleteScorecard() {
        console.log("deleting " + this.props.scorecard.dbScorecard.pk + " " + this.props.scorecard.dbScorecard.sk);
        this.setState({
            showDeleteModal: false
        });
    }

    getTeeTimeString() {
        let teeTimeMoment = moment.utc(this.props.scorecard.teeTime).local();
        if (teeTimeMoment.format("YYYY") == moment().format("YYYY")) {
            return teeTimeMoment.format("dddd, MMMM D · h:mm a");
        }
        return teeTimeMoment.format("dddd, MMMM D, YYYY · h:mm a");
    }

    getDropdown() {
        if (false && localStorage.getItem('username') == this.props.scorecard.player) {
            return (<div>
                <DropdownButton id="dropdown-basic-button" title="" className="dd-button">
                    <Dropdown.Item onClick={this.showDeleteModal}>Delete</Dropdown.Item>
                </DropdownButton>
            </div>);
        }
    }

    render() {

        return (
            <div>         
                <Card>
                    <Card.Body>
                        <Col>
                            <div className="d-flex flex-row justify-content-between scorecard-header">
                                <div>
                                    <Link to={{pathname: `/player/${this.props.scorecard.player}`}}><h5>{this.props.scorecard.getScorecardDisplayName()}</h5></Link>
                                </div>
                                {this.getDropdown()}
                            </div>
                            <Row>
                                {this.getCourseElement()}
                            </Row>
                            <Row>
                                <div className="tee-time">{this.getTeeTimeString()}</div>
                            </Row>
                            {this.getScorecard()}
                        </Col>
                    </Card.Body>            
                </Card>
            
                <Modal show={this.state.showDeleteModal} onHide={this.hideDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete scorecard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete your scorecard from {this.props.scorecard.course} on {moment.utc(this.props.scorecard.teeTime).local().format("LLLL")}?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.hideDeleteModal} >
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={this.deleteScorecard} >
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Scorecard;