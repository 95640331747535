import React from 'react';
import 'components/addScorecard/addScorecard.css';  
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';

class Signup extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            username: '',
            name: ''
        };
  
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.signUp = this.signUp.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUsernameChange(event) {
        this.setState({
            username: event.target.value
        });
    }

    handleNameChange(event) {
        this.setState({
            name: event.target.value
        });
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        });
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    }

    async signUp() {
        console.log("signing up")
        try {
            const { user } = await Auth.signUp({
                username: this.state.username,
                password: this.state.password,
                attributes: {
                    email: this.state.email,
                    name: this.state.name 
                }
            });
            console.log(user);
            this.props.history.push('/login');
        } catch (error) {
            console.log('error signing up:', error);
        }
    }
    
    handleSubmit(event) {
        this.signUp();
        event.preventDefault();
    }

    render() {
        return (
            <Card>
                <Card.Body>
                <Form>
                    
                    <Form.Row>
                        <Form.Group as={Col} controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" value={this.state.username} onChange={this.handleUsernameChange} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formDisplayName">
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control type="text" value={this.state.name} onChange={this.handleNameChange} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" value={this.state.email} onChange={this.handleEmailChange} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" value={this.state.password} onChange={this.handlePasswordChange} />
                        </Form.Group>
                    </Form.Row>                   
                    <Button variant="primary" onClick={this.handleSubmit}>
                        Submit
                    </Button>                                           
                </Form>
                </Card.Body>
            </Card>
        );
    }
}

export default Signup;