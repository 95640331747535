import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Card from 'react-bootstrap/Card';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import 'components/player/cards/holepctcard.css';  
import Stats from 'helpers/stats';
import ScorecardModel from "model/scorecardModel";
import { Carousel } from "react-bootstrap";

const GRAPH_Y_TITLE = "Percentage per Round";

const TREND_COLORS = {
    GOOD: "#008000",
    BAD: "#C00000",
};

const BUTTON_TEXT = {
    TEXT: "Chart",
    CHART: "Return",
};

const SERIES_NAMES = {
    BIRDIE: "% Birdies",
    PAR: "% Pars",
    BOGEY: "% Bogeys",
    DOUBLE_BOGEY: "% Double Bogeys",
    TRIPLE_BOGEY: "% Triple Bogey +"
};

const GRAPH_X_AXIS_STATES = {
    ROUND: "Round",
    DATE: "Date",
};

const CARD_WIDTHS = {
    TEXT: "",
    CHART: "100%",
};

interface AppProps { 
    player: string;
    propsId: string;
    selectedScorecards: Array<ScorecardModel>;
    previousScorecards: Array<ScorecardModel>;
}
 
interface AppState {
    displayExpanded: boolean;
    graphXAxisState: string;
}

class HolePctCard extends React.Component<AppProps, AppState> {

    stats : {
        parPct: string;
        bogeyPct: string;
        doublePct: string;
        birdiePct: string;
        triplePct: string;
    }
    trends : {
        parPct: string;
        bogeyPct: string;
        doublePct: string;
        birdiePct: string;
        triplePct: string;
    }
    propsOldId: string;
    chartOptions: any;
    width: string;
    trendColors : {
        par: string;
        bogey: string;
        double: string;
        birdie: string;
        triple: string;
    };
    button_text: string;

    constructor(props: any){
        super(props);

        this.propsOldId = "";
        this.width = "";
        this.button_text = BUTTON_TEXT.TEXT
        this.stats = {
            parPct: "",
            bogeyPct: "",
            doublePct: "",
            birdiePct: "",
            triplePct: "",
        };
        this.trends = {
            parPct: "",
            bogeyPct: "",
            doublePct: "",
            birdiePct: "",
            triplePct: "",
        };
        this.trendColors = {
            par: "",
            bogey: "",
            double: "",
            birdie: "",
            triple: "",
        };
        this.chartOptions = {
            title: {
                text: "",
                floating: true,
            },
            series: [
                { name: SERIES_NAMES.BIRDIE, data: [], type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.PAR, data: [], type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.BOGEY, data: [], type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.DOUBLE_BOGEY, data: [], type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.TRIPLE_BOGEY, data: [], type: 'spline', marker: { enabled: false } },
            ],
            legend: {
                enabled: true,
                verticalAlign: "top",
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: GRAPH_Y_TITLE
                }
                
            },
            tooltip: {

            },
            xAxis: {
                type: "linear"
            }
        };

        this.state = {
            graphXAxisState: GRAPH_X_AXIS_STATES.ROUND,
            displayExpanded: false,
        };
        this.selectDisplay = this.selectDisplay.bind(this);
        this.selectGraphXAxis = this.selectGraphXAxis.bind(this);
    } 

    selectGraphXAxis(selection: string) {
        this.calculateGraphs(selection);
        this.setState({
            graphXAxisState: selection,
        });

    }

    selectDisplay() {
        if(this.state.displayExpanded) {
            this.width = CARD_WIDTHS.TEXT;
            this.button_text = BUTTON_TEXT.TEXT;
        } else {
            this.width = CARD_WIDTHS.CHART;
            this.button_text = BUTTON_TEXT.CHART;
        }
        this.setState({
            displayExpanded: !this.state.displayExpanded,
        });

    }

    calculateStats() {
        let parPct: number;
        let bogeyPct: number;
        let doublePct: number;
        let triplePct: number;
        let birdiePct: number;
        let parPct_super: number;
        let bogeyPct_super: number;
        let doublePct_super: number;
        let triplePct_super: number;
        let birdiePct_super: number;
        let selected_count: number;
        let previous_count: number;

        //get averages for stats
        parPct = 
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.selectedScorecards,0));
        parPct_super = parPct - 
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.previousScorecards,0));
        birdiePct = 
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.selectedScorecards,-1));
        birdiePct_super = birdiePct -
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.previousScorecards,-1));
        bogeyPct = 
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.selectedScorecards,1));
        bogeyPct_super = bogeyPct -
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.previousScorecards,1));
        doublePct = 
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.selectedScorecards,2));
        doublePct_super = doublePct -
            Stats.getAverage(
            Stats.getScoreToParPercentages(this.props.previousScorecards,2));
        triplePct = 
            Stats.getAverage(
            Stats.getGreaterThanScoreToParPercentages(this.props.selectedScorecards,3));
        triplePct_super = triplePct -
            Stats.getAverage(
            Stats.getGreaterThanScoreToParPercentages(this.props.previousScorecards,3));

        //get total counts, assumes all stats have same count
        selected_count = Stats.getScoreToParPercentages(this.props.selectedScorecards,1).length;
        previous_count = Stats.getScoreToParPercentages(this.props.previousScorecards,1).length;

        //if count is zero, then no current valid scores were found. put default message instead.
        //else use calculated values
        if(selected_count === 0) {
            const message = "N/A";
            this.stats.doublePct = message;
            this.stats.bogeyPct = message;
            this.stats.parPct = message;
            this.stats.birdiePct = message;
            this.stats.triplePct = message;
        } else {
            this.stats.doublePct = doublePct.toFixed(0) + "%";
            this.stats.bogeyPct = bogeyPct.toFixed(0) + "%";
            this.stats.parPct = parPct.toFixed(0) + "%";
            this.stats.birdiePct = birdiePct.toFixed(0) + "%";
            this.stats.triplePct = triplePct.toFixed(0) + "%";
        }

        //if either count is zero, then do not show trending value.
        //else show trending data
        if(selected_count === 0 || previous_count === 0) {
            const message = "";
            this.trends.doublePct = message;
            this.trends.bogeyPct = message;
            this.trends.parPct = message;
            this.trends.birdiePct = message;
            this.trends.triplePct = message;
        } else {
            if(triplePct_super <= 0) { 
                this.trends.triplePct = "\u2193" + Math.abs(triplePct_super).toFixed(0);
                this.trendColors.triple = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.triplePct = "\u2191" + triplePct_super.toFixed(0);
                this.trendColors.triple = TREND_COLORS.BAD;
            }

            if(doublePct_super <= 0) { 
                this.trends.doublePct = "\u2193" + Math.abs(doublePct_super).toFixed(0);
                this.trendColors.double = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.doublePct = "\u2191" + doublePct_super.toFixed(0);
                this.trendColors.double = TREND_COLORS.BAD;
            }

            if(bogeyPct_super <= 0) { 
                this.trends.bogeyPct = "\u2193" + Math.abs(bogeyPct_super).toFixed(0);
                this.trendColors.bogey = TREND_COLORS.GOOD;
            }
            else { 
                this.trends.bogeyPct = "\u2191" + bogeyPct_super.toFixed(0);
                this.trendColors.bogey = TREND_COLORS.BAD;
            }

            if(parPct_super <= 0) { 
                this.trends.parPct = "\u2193" + Math.abs(parPct_super).toFixed(0);
                this.trendColors.par = TREND_COLORS.BAD;
            }
            else { 
                this.trends.parPct = "\u2191" + parPct_super.toFixed(0);
                this.trendColors.par = TREND_COLORS.GOOD;
            }

            if(birdiePct_super <= 0) { 
                this.trends.birdiePct = "\u2193" + Math.abs(birdiePct_super).toFixed(0);
                this.trendColors.birdie = TREND_COLORS.BAD;
            }
            else { 
                this.trends.birdiePct = "\u2191" + birdiePct_super.toFixed(0);
                this.trendColors.birdie = TREND_COLORS.GOOD;
            }
        }
    }

    calculateGraphs(xAxis: string) {
        var DataBirdie;
        var DataBogey;
        var DataPar;
        var DataDoubleBogey;
        var DataTripleBogey;
        var smoothDataBirdie;
        var smoothDataBogey;
        var smoothDataPar;
        var smoothDataDoubleBogey;
        var smoothDataTripleBogey;
        var xAxisData = [];
        var finalDataBirdie = [];
        var finalDataPar = [];
        var finalDataBogey = [];
        var finalDataDoubleBogey = [];
        var finalDataTripleBogey = [];
        var type;

        DataBogey = Stats.getScoreToParPercentages(this.props.selectedScorecards,1);
        DataDoubleBogey = Stats.getScoreToParPercentages(this.props.selectedScorecards,2);
        DataTripleBogey = Stats.getGreaterThanScoreToParPercentages(this.props.selectedScorecards,3);
        DataPar = Stats.getScoreToParPercentages(this.props.selectedScorecards,0);
        DataBirdie = Stats.getScoreToParPercentages(this.props.selectedScorecards,-1);

        switch(xAxis) {
            case GRAPH_X_AXIS_STATES.ROUND:
                for(let i = 1; i <= DataBogey.length; i++) {
                    xAxisData.push(i);
                }
                type="linear";
                break;
            default: //case GRAPH_X_AXIS_STATES.DATE:
                xAxisData = Stats.getDates(this.props.selectedScorecards);
                type="datetime";
                break;
        }

        smoothDataBirdie = Stats.getSmoothScores(DataBirdie).map(x => Math.round(x*100)/100);
        smoothDataPar= Stats.getSmoothScores(DataPar).map(x => Math.round(x*100)/100);
        smoothDataBogey = Stats.getSmoothScores(DataBogey).map(x => Math.round(x*100)/100);
        smoothDataDoubleBogey = Stats.getSmoothScores(DataDoubleBogey).map(x => Math.round(x*100)/100);
        smoothDataTripleBogey = Stats.getSmoothScores(DataTripleBogey).map(x => Math.round(x*100)/100);

        for(let i = 0; i < DataBogey.length; i++) {
            finalDataBirdie.push([xAxisData[i],smoothDataBirdie[i]]);
            finalDataBogey.push([xAxisData[i],smoothDataBogey[i]]);
            finalDataPar.push([xAxisData[i],smoothDataPar[i]]);
            finalDataDoubleBogey.push([xAxisData[i],smoothDataDoubleBogey[i]]);
            finalDataTripleBogey.push([xAxisData[i],smoothDataTripleBogey[i]]);
        }
        
        this.chartOptions = {
            title: {
                text: "",
                floating: true,
            },
            series: [
                { name: SERIES_NAMES.BIRDIE, data: finalDataBirdie, type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.PAR, data: finalDataPar, type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.BOGEY, data: finalDataBogey, type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.DOUBLE_BOGEY, data: finalDataDoubleBogey, type: 'spline', marker: { enabled: false } },
                { name: SERIES_NAMES.TRIPLE_BOGEY, data: finalDataTripleBogey, type: 'spline', marker: { enabled: false } },
            ],
            legend: {
                enabled: true,
                verticalAlign: "top",
            },
            credits: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: GRAPH_Y_TITLE
                }
                
            },
            tooltip: {

            },
            xAxis: {
                type: type
            }
        };
    }

    render() {
        if(this.props.propsId !== this.propsOldId) { //year, month, course filter changed
            this.calculateGraphs(this.state.graphXAxisState);
            this.calculateStats();
            this.propsOldId = this.props.propsId;
        }
        return (
            <div className="wrapper" style={{ width: this.width }}>
                <Card className="card">
                    <Card.Title className="card_title">Round Averages</Card.Title>
                    {this.state.displayExpanded && <div className="chart">
                        <HighchartsReact
                                highcharts={Highcharts}
                                options={this.chartOptions}
                        />
                        <DropdownButton id="xAxisDropDown" align="center" title={this.state.graphXAxisState}>
                            <Dropdown.Item onClick={() => this.selectGraphXAxis(GRAPH_X_AXIS_STATES.ROUND)}>{GRAPH_X_AXIS_STATES.ROUND}</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.selectGraphXAxis(GRAPH_X_AXIS_STATES.DATE)}>{GRAPH_X_AXIS_STATES.DATE}</Dropdown.Item>
                        </DropdownButton>
                    </div>}
                    {!this.state.displayExpanded && <div className="text">
                        <Carousel className="carousel" indicators={false} interval={3500}>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.birdiePct}</p>
                                    <h1 className="value">{this.stats.birdiePct}</h1>
                                    <p className="trend" style={{color: this.trendColors.birdie}} >{this.trends.birdiePct}</p>
                                </div>
                                <p className="description">Birdies</p>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.parPct}</p>
                                    <h1 className="value">{this.stats.parPct}</h1>
                                    <p className="trend" style={{color: this.trendColors.par}}>{this.trends.parPct}</p>
                                </div>
                                <p className="description">Pars</p>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.bogeyPct}</p>
                                    <h1 className="value">{this.stats.bogeyPct}</h1>
                                    <p className="trend" style={{color: this.trendColors.bogey}}>{this.trends.bogeyPct}</p>
                                </div>
                                <p className="description">Bogeys</p>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.doublePct}</p>
                                    <h1 className="value">{this.stats.doublePct}</h1>
                                    <p className="trend" style={{color: this.trendColors.double}}>{this.trends.doublePct}</p>
                                </div>
                                <p className="description">Double Bogeys</p>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="carousel-content">
                                    <p className="trend" style={{visibility: "hidden"}}>{this.trends.triplePct}</p>
                                    <h1 className="value">{this.stats.triplePct}</h1>
                                    <p className="trend" style={{color: this.trendColors.triple}}>{this.trends.triplePct}</p>
                                </div>
                                <p className="description">Triple Bogey +</p>
                            </Carousel.Item>
                        </Carousel>
                    </div> }
                    <Button className="button" variant="link" onClick = {this.selectDisplay} >{this.button_text}</Button>
                </Card>                         
            </div>
        );
    }
}

export default HolePctCard;