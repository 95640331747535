import React from 'react';
import Scorecard from 'components/scorecard/scorecard';
import 'components/viewScorecards/viewScorecard.css';
import ScorecardModel from 'model/scorecardModel'
import moment from "moment";
import { API } from 'aws-amplify';

interface AppProps {
    player: string;
}
 
 interface AppState {
    scorecards: Array<ScorecardModel>;
    count: number;
    isPlayer: boolean;
}

class ViewScorecards extends React.Component<AppProps, AppState> {
    constructor(props: any){
        super(props);
        this.state = { 
            scorecards: Array<ScorecardModel>(),
            count: 0,
            isPlayer: false
        }
        this.loadMoreScorecards = this.loadMoreScorecards.bind(this);
    }

    componentDidMount() {
        this.loadScorecards();
    }

    loadScorecards() {
        const apiName = 'shankapi'; // TODO: move this outside class

        let path = '';
        if (this.props.player !== "") {
            this.setState({isPlayer: true});
            path = `/getScorecardsForPlayer/${this.props.player}`;
        }
        else {
            path = `/getScorecards/${this.state.count}`; 
        }
        const myInit = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false // OPTIONAL (return the entire Axios response object instead of only response.data)
        };

        API
        .get(apiName, path, myInit)
        .then(response => {
            this.setState({count: this.state.count + response.length}); 
            let newScores = response.map((d: any) => new ScorecardModel(d));
            this.setState({ scorecards: this.state.scorecards.concat(newScores)});
        })
        .catch(error => {
            console.log(error.response);
        });
    }

    loadMoreScorecards(e: any) {
        e.preventDefault();
        this.loadScorecards();
    }

    render() {
        let loadMore;
        let scorecards;
        if (!this.state.isPlayer) {
            loadMore = <a className="loadMore" href="" onClick={this.loadMoreScorecards}>Load more</a>;
            scorecards = this.state.scorecards
                .sort((a,b) => moment(b.lastUpdateTime).diff(moment(a.lastUpdateTime)))
                .map(scorecard => {
                    return <div key={scorecard.getUniqueKey()} className="scorecardContainer"><Scorecard scorecard={scorecard} /></div>;
                });           
        }
        else {
            scorecards = this.state.scorecards
                .sort((a,b) => moment(b.teeTime).diff(moment(a.teeTime)))
                .map(scorecard => {
                    return <div key={scorecard.getUniqueKey()} className="scorecardContainer"><Scorecard scorecard={scorecard} /></div>;
                });  
        }
        return (
            <div>
                {scorecards}
                {loadMore}
            </div>
        );
    }
}

export default ViewScorecards;