import { Moment } from "moment";
import { isEmpty } from "@aws-amplify/core";
import ScoreForHole from 'model/scoreForHole';

class ScorecardModel {
    readonly player: string;
    readonly course: string;
    readonly teeTime: Moment;
    readonly scores: Array<number>;
    readonly scoresForHole: Array<ScoreForHole>;
    readonly length: number;
    readonly startingHole: number;
    readonly totalScore: number;
    readonly totalScoreToPar: number;
    readonly holesPlayed: number;
    readonly lastUpdateTime: Moment;
    readonly hasCourseData: boolean;
    readonly displayName: string;
    readonly awards: Array<String>;
    readonly city: string;
    readonly state: string;
    readonly dbScorecard: any;
    readonly showDantrap: boolean;

    constructor(dbScorecard: any) {
        this.player = dbScorecard.player;
        this.course = dbScorecard.course;
        this.teeTime = dbScorecard.teeTime;
        this.scores = [];//dbScorecard.scores.map((sc: ScoreForHole) => sc.score)
        this.length = dbScorecard.length;
        this.startingHole = dbScorecard.startingHole;
        this.totalScore = dbScorecard.totalScore;
        this.totalScoreToPar = dbScorecard.totalScoreToPar;
        this.holesPlayed = dbScorecard.holesPlayed;
        this.lastUpdateTime = dbScorecard.lastUpdateTime;
        this.hasCourseData = dbScorecard.hasCourseData;
        this.displayName = dbScorecard.displayName;
        this.awards = dbScorecard.awards;
        this.city = dbScorecard.city;
        this.state = dbScorecard.state;
        
        this.dbScorecard = dbScorecard;

        this.scoresForHole = [];
        dbScorecard.scores.forEach((element: any) => {
            this.scoresForHole.push(new ScoreForHole(element.holeNumber, element.score, element.scoreToPar));
        });

        this.showDantrap = false;
        if (this.course == "Ridges at Sand Creek") {
            this.showDantrap = true;
        }
    }

    public getScorecardDisplayName() {
        return isEmpty(this.displayName) ? this.player : this.displayName;
    }

    public getUniqueKey() {
        return this.player + this.teeTime.toString();
    }

    public getSide() {
        let sideString = "";
        if (this.holesPlayed === 9){
            if (this.startingHole === 1) {
                sideString = " - Front 9";
            }
            else if (this.startingHole === 10) {
                sideString = " - Back 9"
            }
        }        
        return sideString;
    }

    public getFrontNine() {
        return this.scoresForHole.slice(0, Math.min(this.scoresForHole.length, 9));
    }

    public getBackNine() {
        if (this.scoresForHole.length > 9) {
            return this.scoresForHole.slice(9, this.scoresForHole.length);
        }
        return [];
    }

    private getScoreArrayFromJson(scores: any) {
        let scoreArray = new Array<number>();
        let scoreJson = JSON.parse(scores);
        for (var key of Object.keys(scoreJson)) {
            scoreArray.push(parseInt(scoreJson[key]));
        }
        return scoreArray;
    }
}

export default ScorecardModel;