import React from "react";
import { API } from 'aws-amplify';
import ScorecardModel from 'model/scorecardModel'
import ScorecardMini from 'components/scorecardMini/scorecardMini';
import { RouteComponentProps } from 'react-router-dom';
import 'components/course/course.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface AppProps {
    courseName: string;
    city: string;
    state: string;
}
 
 interface AppState {
    scorecards: Array<ScorecardModel>;
    weeklyLeaderboard: Array<ScorecardModel>;
}

class Course extends React.Component<RouteComponentProps<AppProps>, AppState> {
    constructor(props: any){
        super(props);
        this.state = { 
            scorecards: Array<ScorecardModel>(),
            weeklyLeaderboard: Array<ScorecardModel>(),
        }
    }

    componentDidMount() {
        const apiName = 'shankapi';
        const path = `/course/${this.props.match.params.courseName}/${this.props.match.params.city}/${this.props.match.params.state}`; 
        const myInit = { // OPTIONAL
            headers: { 'Content-Type': 'application/json'}, // OPTIONAL
            response: false // OPTIONAL (return the entire Axios response object instead of only response.data)
        };

        API
        .get(apiName, path, myInit)
        .then(response => {
            console.log(response);

            if (response.allTimeLeaderboard) {
                this.setState({ scorecards: response.allTimeLeaderboard.map((d: any) => new ScorecardModel(d)) });
            }

            if (response.weeklyLeaderboard) {
                this.setState({ weeklyLeaderboard: response.weeklyLeaderboard.map((d: any) => new ScorecardModel(d)) });
            }
        })
    }

    getWeeklyLeaderboard() {
        let leaderboard = [<h5>Leaderboard (weekly)</h5>];
        this.state.weeklyLeaderboard
        .sort((a,b) => a.totalScore - b.totalScore)
        .map((scorecard, place) => {
            leaderboard.push(<ScorecardMini scorecard={scorecard} place={place + 1} />);
        });
        return leaderboard;
    }

    getAllTimeLeaderboard() {
        let leaderboard = [<h5>Leaderboard (all time)</h5>];
        this.state.scorecards
        .sort((a,b) => a.totalScore - b.totalScore)
        .map((scorecard, place) => {
            leaderboard.push(<ScorecardMini scorecard={scorecard} place={place + 1} />);
        });
        return leaderboard;
    }

    render() {
        return (
            <div>
                <h3>{this.props.match.params.courseName}</h3>
                <p>{this.props.match.params.city}, {this.props.match.params.state}</p>
                <hr />
                <Container>
                    <Row>
                        {this.state.weeklyLeaderboard.length > 0 && <Col><div className="leaderboard">{this.getWeeklyLeaderboard()}</div></Col>}
                        {this.state.scorecards.length > 0 && <Col><div className="leaderboard">{this.getAllTimeLeaderboard()}</div></Col>}
                    </Row>
                </Container>     
            </div>
        );
    }
}

export default Course;