import React from "react";
import AddScorecard from 'components/addScorecard/addScorecard';
import ViewScorecards from 'components/viewScorecards/viewScorecards';
import useIsLoggedIn from 'helpers/useIsLoggedIn';

class Home extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        let isLoggedIn = localStorage.getItem('isUserLoggedIn') == 'true';
        console.log("is logged in from storage " + isLoggedIn);
        return (
            <div className="Content">
                {isLoggedIn && <h3>Submit a scorecard</h3>}
                {isLoggedIn && <AddScorecard />}
                <h3>Recent rounds</h3>
                <ViewScorecards player={""} />
            </div>
        );
    }
}

export default Home;